header.banner {
  text-align: center;
  z-index: $header-nav-zindex;
  background: $header-background-color;
}
.topHeader {
  background: $ped-green;
  color: $light;
  padding: .2em 0 .6em;
  .contain {
    width: 96%;
    padding: 0;
    max-width: 100%;
    min-height: 30px;
    .topFormatContainer {
      float: right;
      width: 22%;
    }
  }
  .socialOrder {
    float: right;
    ul li {
      display: inline-block;
      a {
        color: $light;
        font-size: 24px;
      }
    }
  }
  .phoneOrder {
    float: left;
    .phoneNumber {
      float: none;
      color: $light;
      font-size: 24px;
    }
  }
}
h1#logo { 
  margin: 0 0 0.2em !important;
}
header.banner .container {
  height: inherit;
  width: 100%;

  .navbar-brand {
    width: 100%;
    padding: 0;
    height: inherit;
    h1#logo {
      margin: 0;
      height: inherit;
    }
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  a.phoneNumber {
    line-height: $fields-height;
  }
  .social-icons ul {
    margin: 0;
    li {
      display: inline-block;
      line-height: 1em;
      padding: 0;
    }
    a {
      padding-right: 6px;
      line-height: $fields-height;
      &:hover i:before {
        
      }
    }
  }

}

@media screen and (min-width: $small) {
  header.banner {
    .navbar-brand {
      float: none;
    }
    nav {
      clear: both;
      text-align: center;
    }
  }
}

@media screen and (min-width: $medium) {
  header.banner {
    .container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      > * {
        // flex: 1;
      }
    }
    .navbar-brand {
      float: left;
      h1#logo {

      }
      img {
        float: left;
        //height: 100%;
      }
    }
    #headerMenu {
      float: right;
    }
    .phoneNumber {
      float: right;
    }
    .social-icons {
      clear: both;
      text-align: right;
      ul {
        padding: 0;
      }
      li {
      }
    }
    nav {
      clear: both;
      float: right;
    }
  }
}
@media screen and (min-width: 900px) and (max-width: 1095px) {
  .topHeader {
    .contain .topFormatContainer {
       width: 30%;
    }
  }
}
@media screen and (max-width: 900px) {
  .topHeader {
    .contain .topFormatContainer {
       width: 100%;
       float: none;
    }
    .phoneOrder .phoneNumber {
      padding-left: 20px;
    }
  }
  header.banner .container .navbar-brand {
    float: left;
    img {
      display: inline-block;
    }
  }
}



