.hero-section {
  padding: 0;
}
.hero.jumbotron {
  border-radius: 0;
  margin: 0;
  /* flexbox to improve centering */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
}
/* Text inside of Hero */
.hero_unit {
  animation: fadeInRight 2s;
  color: $light;
  text-align: center; 
  h2 {
    text-shadow: 4px 4px 9px $dark;
  }
  p {
    text-shadow: 4px 4px 9px $dark;
  }
}

/* Btn testing animation on hover */
.hero .btn:hover {
  animation: tada 1s;
}
@media only screen 
    and (max-device-width: 640px), 
    only screen and (max-device-width: 667px), 
    only screen and (max-width: 480px) { 
  .hero_unit h2 {
    padding: 0px 19px;
  }
}

@media screen and (min-width: $medium) {
  .hero.jumbotron {
    height: 70vh;
    min-height: 550px;
  }
}

// Overlay
.hero-section {
  position: relative;
  overflow: hidden;
}
.overlay:after {
  display: block;
  content: " ";
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.26);
}
// Parallax Hero
.heroParallax {
  .jumbotron {
    background: transparent;
    h2 {
      text-shadow: 4px 4px 9px $dark;
    }
    h4{
      text-shadow: 4px 4px 9px $dark;
          margin: 0em 0em 0.5em 0em;
    }
  }
}
