/*
 * Homepage
 */
// Header
.home {
  header.banner {

  }
}

@media screen and (min-width: $medium) {

}

@media screen and (min-width: $large) {

}