// physician section
.employeeSection {
  padding: 0em 0em 4em 0em;
  .singleEmployee {
    padding-bottom: 4.5em;
  	h3 {
		margin: .5em 0 0.1em;
		color: $ped-purple-dk;
  	}
  	h4 {
  		margin: 0em 0 0.5em;
  		color: $lightgray;
  	}
  	img {
  	    width: 300px;
   	    height: 300px;
  	}
  	&:hover {
  		img {
  			opacity: .8;
  		}
  		h3 {
		color: $ped-green;
	  	}
  	}
  }
}
.employeePostHead {
  padding: 2em 0em 0em 0em !important;
}
.employeePostHeader {
  img {
        width: 300px;
        height: 300px;
  }
}
.employeePostContent {
  padding: 3em 0em 4em 0em !important;
}
