// Single Page
.titleSection {
  text-align: center;
  padding: 2em 0; 
  h2 {
    margin: .5em 0em 0.4em 0em;
  }
}
// HR
.hr-single {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: auto;
  margin-left: auto;
  border: 1px solid $ped-green;
  width: 50%;
}
.blogPostContainer:last-child {
  .hr-single {
    display: none;
  }
}
// list of resources on resource page
.resourceList {
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
  h4 {
    margin: 0em 0em 0.5em 0em;
  }
}
@media screen and (max-width: $medium) {
  .resourceList {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    h4 {
      margin: 0em 0em 1.5em 0em;
      font-size: 15px;
      line-height: 1;
      text-align: center;
    }
  }
}
.post-container {
	text-align: center;
	a {
		text-decoration: none;
	}
	p {
		margin-bottom: 2em;
	}
	h3 {
		margin: 1em 0 0.5em;
	}
}