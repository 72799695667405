.addressMapMain {
	padding: 0;
	margin: 0;
}
.addressMapBlock {
	padding: 0;
	height: 350px;
}
.addressBlock {
	background: $ped-red;
	padding: 0;
	height: 350px;
	.addressContent {
		padding: 3em 2em;
	}
	h2 {
		margin: 0 0 0.5em 0em;
		color: $light;
	}
	h4 {
		color: $light;
		font-size: 1em !important;
	}
}
.mapBlock {
	padding: 0;
	height: 350px;
	.mapContent {
		iframe{
			display:block;
   	 		width:100%;
   	 		min-height: 350px;
		}
	}
}
@media screen and (max-width: $medium) { 
	.addressMapBlock {
		height: 100%;
	}
}

