/* Add the classes here for each page you want a transparent header
 * i.e:
 *  .home, .about, .contact {
 *    stuff { foo: bar; }
 *   }
 */

.hero.jumbotron {
    /* Change to 100vh if you want the full screen height */
    height: 80vh;
}