/* About */
.home {
  .about {
    padding: 2.5em 0;
    text-align: center;
    background: $ped-blue;
    color: $dark;
    .box {
    	max-width: 35em;
    }
    h3 {
    	color: #7067A1;
    	margin: 0.5em 0em 0.5em;
    }
    h4 {
        color: #7067A1;
        margin: 0.5em 0 0.5em;
    }
    p {
		color: #7067A1;
        font-size: 20px;
    }
  }
}
.aboutFooterImage {
    .parallax-container {
        min-height: 350px !important;
    }
}
