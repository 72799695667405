.blurbs {
  background: $ped-green;
  padding: 0;
}
.blurb {
  margin: 3em 0;
  text-align: center;
  h1,h2,h3,h4,h5 {
    color: $blurb-heading-color;
    margin: 0.5em 0 0.5em;
    font-size: 2.2em;
  }
  p {
    color: $blurb-text-color;
    font-size: 20px;
  }
  &:hover i {
    color: $blurb-icon-hover;
    transform: rotate(15deg);
  }
  &:hover i:after {
    background: $blurb-icon-background-hover;
  }
  i {
    transition: 400ms all ease;
    font-size: 5em;
    color: $blurb-icon;
    position: relative;
    width: 100%;
    height: 1em;
  }
}

@media screen and (max-width: $small) {
  .blurb {
    padding: 2em 0;
    h2 {
      margin: 0.5em 0 0em;
    }
  }
}
@media screen and (min-width: $medium) and (max-width: $medium) {
  .blurb {
    padding: 0;
    h2 {
      margin: 0.5em 0 0em;
    }
  }
}
