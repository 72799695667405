html {
  font-size: 1em;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

body {
  background-color: $sage-body-background;
  font-size: 18px;
  font-weight: 100;
  line-height: 1.45;
  color: $text-color;
  font-family: $font-primary;
  background-size: cover;
  -webkit-font-smoothing: antialiased;
  position: relative;
}

#wpadminbar {
  margin-top: -46px;
}

@media screen and (min-width: 600px) {
  #wpadminbar {
    margin-top: 0;
  }
}

p {
  margin-bottom: 1.3em;
}

h1, h2, h3, h4 {
  margin: 1.414em 0 0.5em;
  font-weight: bold;
  line-height: 1.2;
  font-family: $font-secondary;
}

@media screen and (min-width: $small) {
  h1 {
    margin-top: 0;
    font-size: 3.998em;
  }

  h2 {
    font-size: 2.827em;
  }

  h3 {
    font-size: 1.999em;
  }

  h4 {
    font-size: 1.414em;
  }
}

small, .font_small {
  font-size: 0.707em;
}

li {
  list-style: none;
  padding-left: 1em;
}

ul {
  padding: 0;
}

a {
  color: $body-links;
}

a:hover {
  color: $body-links-hover;
  text-decoration: none !important;
}

main.main {
  padding: 0;
}

@media screen and (min-width: 1500px) {
  .container {
width: 90%;
  }
}


.row {
  margin: 0;
  padding: 4em 0;
}

.content.row {
  padding: 0;
}

.box {
  max-width: $content-width;
  margin-left: auto;
  margin-right: auto;
}

img {
  max-width: 100%;
}

input {
  color: $dark;
  line-height: $fields-height;
  border: 1px solid $lightgray;
  padding: 0;
  font-weight: 300;
}

input[type=text],
input[type=search],
textarea {
  text-indent: 1em;
}

.dark {
  h1, h2, h3, h4, h5,
  p {
    color: $light;
  }
}