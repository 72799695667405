.latestBlogSection {
  padding: 4em 0 !important;
  .recent-blog {
    padding-bottom: 50px;
    margin: 0 !important;
  }
  .latest-blog-header {
    text-align: center;
    margin-bottom: 1em !important;
    margin-top: 0 !important;
    color: $ped-purple-dk;
  }
  i.fa.fa-newspaper-o {
    animation: tada 3s;
  }
  .latest-posts {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;  
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
    font-weight: bold;
    text-align: center;
      .post-container {
        .singlePostBox {
          padding: 10px;
          h3 {
            color: $ped-purple-dk;
            margin: 0em !important;
          }
          a {
            color: $ped-purple-dk;
            &:hover {
              color: $ped-green;
            }
          }
          &:hover {
            h3, a, p {
              color: $ped-green;
            }
          }
        }
      padding: 0px 10px;
      
    }
  }
  .latest-posts > * {
    padding: 10px;
    flex: 2;
  }
}
