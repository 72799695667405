footer {
  padding: 2em 0;
  background: $ped-purple;;
  color: $light;
  text-align: center;
  .topFooter {
    aside.row.widget-container {
      padding: 2em 0;
    }
    .widget {
      width: 100%;
     ul li {
      display: inline-block;
      a {
        color: $light;
        font-size: 24px;
          &:hover {
            color: $ped-yellow;
          }
        }
      }
    }
  }
  .footerBotton {
    text-align: center;
  }
  .footerMiddle {
    .widget ul {
      text-align: center !important;
    }
    ul li {
      display: inline-block;
      a {
        color: $light;
        font-size: 24px;
        &:hover {
          color: $ped-yellow;
        }
      }
    }
  }
  .widget-container {
    @include flexbox(center);
    align-items: flex-start;
    > * {
      min-width: 290px; // Because iPhone 320 subtract .container 15px per side (30px total)
    }
  }
  .widget {
    vertical-align: top;

    h3 {
      text-align: left;
    }
    li {
      list-style-type: circle;
      padding: 0;
      &:after{
        content: "|";
        padding: 0em 1em;
      }
      &:last-child{
        &:after{
          display: none;
        }
      }
    }
    ul {
      padding-left: 2em;
    }
  }
  a {
    color: $light;
  }
  p {
    margin: 0;
  }
  .socialIcons li {
    display: inline-block;
  }
}
@media screen and (max-width: $small) { 
  .widget {
    ul {
      text-align: center;
    }
  }
}
@media screen and (min-width: $medium) {
  footer {
    .widget-container {
      > * {
        min-width: initial;
      }
    }
    .widget {
      display: inline-block;
      width: 24.7%; // 25% didn't work
    }
  }

  .footer-left {
    float: left;
  }
  .footer-right {
    float: right;
    li {
      display: inline-block;
    }
  }
}