// Glyphicons font path
$icon-font-path: "../fonts/";

// Fonts
$font-primary: 'Neuton', serif;
$font-secondary: 'Montserrat', sans-serif;

// !important -  Responsible for cohesive input height & button height
$fields-height: 2.5em;

// Grid settings
$main-sm-columns: 12;
$sidebar-sm-columns: 4;

/* !important: content should never be more than 55-ish characters per line, it just becomes unreadable */
$content-width: 30.55555em;

// Media Query Sizes
$small: 600px;
$medium: 900px;
$large: 1200px;

// Menu & Header helpers
$header-nav-zindex: 999;

/*
 * Colors
 */
$dark: #333;
$light: white;
$gray: #777;
$lightgray: #999999;

// Brand Colors Specifics - Delete these value and update the rest of the sheet to whatever you want!
$emerald: #2ecc71;
$belizehold: #2980b9;
$carrot: #e67e22;
$pink: #eaaae4;

$ped-green: #A3E0A4;
$ped-purple: #A89AF1;
$ped-red: #FE7D68;
$ped-blue: #AFF7F6;
$ped-yellow: #FCFE91;
$ped-purple-dk: #7067A1;
// General
$sage-body-background: $light;
$text-color: $dark;
$header-color: $carrot;
$body-links: $ped-purple-dk;
$body-links-hover: $lightgray;

// Header
$header-background-color: $ped-purple;

// Non-Mobile Nav
$nav-text-color: $light; /* probably not needed, but just in case */
$nav-link-color: $light;
$nav-link-color-hover: $ped-yellow;
$nav-dropdown-background: $carrot;

// Mobile Nav Button
$mobile-nav-button-text: $light;
$mobile-nav-border-color: $light;
$mobile-nav-button-background: $ped-purple-dk;

// Mobile Nav Popup
$mobile-nav-popup-background: $ped-purple-dk;
$mobile-nav-text-color: $light;
$mobile-nav-link-color: $light;

// Buttons
$button-background: $ped-purple;
$button-border: $ped-purple;
$button-text: $ped-yellow;
// Buttons:hover
$button-background-hover: $ped-yellow;
$button-border-hover: $ped-yellow;
$button-text-hover: $ped-purple;

// Blurbs
$blurb-heading-color: $light;
$blurb-text-color: $light;
// Blurb Icons
$blurb-icon: $light;
$blurb-icon-border: $light;
$blurb-icon-background: transparent;
// Blurb Icon Hover
$blurb-icon-hover: $light;
$blurb-icon-border-hover: $carrot;
$blurb-icon-background-hover: $carrot;

